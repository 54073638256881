/* ResponsiveContainer.css */

.responsive-container {
    max-width: 800px; /* Adjust the maximum width as needed */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
  }
  
  button {
    margin-bottom: 5px; /* Add some space between the button and the DataPost component */
    padding: 8px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .logout-button{
    margin-left: 5px;
  }
  
  /* Switch Component Styles */

.switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.switch-label {
  margin-right: 10px;
  font-size: 14px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Style for the slider (the visible part of the switch) */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px; /* Rounded slider */
}

/* Style for the slider when the switch is in the "on" position */
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%; /* Rounded slider handle */
}

/* Style for the switch when it's checked (on) */
input:checked + .slider {
  background-color: #2196F3;
}

/* Style for the slider when the switch is checked (on) */
input:checked + .slider:before {
  transform: translateX(20px);
}

/* App.css */
.navbar {
  background-color: #333;
  overflow: hidden;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  float: left;
}

.nav-item a {
  display: block;
  color: white;
  text-align: center;
  padding: 10px 16px;
  text-decoration: none;
}

.nav-item a:hover {
  background-color: #939090;
  color: black;
}

.active a{
  background-color: #ddd;
  color: black;
}

.horizontal-stats-container {
  display: flex;
}

.stat {
  margin-right: 20px; /* Adjust the margin as needed for spacing between stats */
}

.stats{
  display: flex;
}

h3,p{
  color: #cdcccc;
}
