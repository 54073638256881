/* DataPostForm.css */

.data-post-container {
    position: relative;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #717171;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #414141;
  
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #c8c8c8;
  }
  
  textarea {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #5a5959;
    border-radius: 4px;
    width: 100%;
    background-color: #9d9d9d;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  button {
    padding: 8px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  

  /* Add this CSS to your stylesheets */

.open-modal-btn {
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
}

.confirm-modal {
  width: 500px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  height: 200px; /* Adjust the height as needed */
  background-color: #3498db; /* Set the background color */
  color: #fff; /* Set the text color */
  z-index: 1000; /* Set the z-index to make it appear on top */
}

.confirm-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
}

.confirm-btn,
.cancel-btn {
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
}

.confirm-btn {
  background-color: #27ae60;
  color: #fff;
}

.cancel-btn {
  background-color: #e74c3c;
  color: #fff;
}
