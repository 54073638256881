.custom-toast {
    position: fixed;
    top: 43px;
    right: 5px;
    width: 300px;
    margin: 0 auto;
    color: #fff;
    padding: 14px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

.success{
    background-color: #00a511;
}
  
.toast-body {
    font-size: 14px;
}

.error{
    background-color: #ff3724;
}
  